import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Register.css';
import Modal from './Modal';

function Register() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null); // Success message state
  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://www.finanon.com/api/register', {
        username,
        email,
        password,
      });
      console.log('User registered:', response.data);

      // Set success message
      setSuccessMessage('Registration successful! Redirecting to login...');
    } catch (err) {
      console.error(err);
      setError(err.response?.data?.error || 'Registration failed');
    }
  };

  // Automatically navigate to login after a brief delay when successMessage is set
  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => {
        navigate('/login');
      }, 2000); // 2-second delay

      // Clean up the timer when component unmounts or successMessage changes
      return () => clearTimeout(timer);
    }
  }, [successMessage, navigate]);

  return (
    <div className="register-container">
      <form onSubmit={handleRegister} className="register-form">
        <h2>Create an Account</h2>
        {error && <p className="error-message">{error}</p>}
        <div>
          <label>Username</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Email</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Password</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit">Register</button>
      </form>

      {/* Success message modal */}
      <Modal isOpen={!!successMessage}>
        <p className="modal-message">{successMessage}</p>
      </Modal>
    </div>
  );
}

export default Register;
