import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getLoggedInUsername } from './utils/auth';

function Navbar() {
  const [username, setUsername] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false); // State to control the dropdown menu
  const navigate = useNavigate();

  useEffect(() => {
    const updateUsername = () => {
      const loggedInUsername = getLoggedInUsername();
      setUsername(loggedInUsername);
    };

    updateUsername();
    window.addEventListener('authChanged', updateUsername);

    return () => {
      window.removeEventListener('authChanged', updateUsername);
    };
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    setUsername(null);
    window.dispatchEvent(new Event('authChanged'));
    navigate('/login');
  };

  // Determine if screen width is mobile-sized
  const isMobile = window.innerWidth <= 768;

  return (
    <nav style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '10px 20px',
      backgroundColor: '#222',
      color: 'white',
      width: '100%',
      height: '60px', // Fixed height for the navbar
      position: 'fixed',
      top: 0,
      left: 0,
      zIndex: 1000,
      fontFamily: 'RB, sans-serif',
      boxSizing: 'border-box',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    }}>
      {/* Logo as a Link */}
      <div style={{ fontSize: '24px', textAlign: 'left' }}>
        <Link to="/" style={{ color: 'white', textDecoration: 'none' }}>
          FinAnon
        </Link>
      </div>

      {/* Hamburger Icon for Mobile */}
      {isMobile ? (
        <div onClick={() => setMenuOpen(!menuOpen)} style={{ cursor: 'pointer' }}>
          &#9776; {/* Unicode for hamburger icon */}
        </div>
      ) : (
        // Desktop Navigation Links
        <ul style={{
          display: 'flex',
          listStyleType: 'none',
          margin: 0,
          padding: 0,
          gap: '20px',
          justifyContent: 'space-around',
          textAlign: 'left',
        }}>
          <li><Link to="/register" style={{ color: 'white', textDecoration: 'none' }}>Create Account</Link></li>
          <li><Link to="/dashboard" style={{ color: 'white', textDecoration: 'none' }}>Dashboard</Link></li>
          <li><Link to="/stripe-payment" style={{ color: 'white', textDecoration: 'none' }}>Buy Credits</Link></li>
          <li><Link to="/crypto-payment" style={{ color: 'white', textDecoration: 'none' }}>Pay with Crypto</Link></li>
          {username ? (
            <li><Link to="#" onClick={handleLogout} style={{ color: 'white' }}>Log Out</Link></li>
          ) : (
            <li><Link to="/login" style={{ color: 'white' }}>Login</Link></li>
          )}
        </ul>
      )}

      {/* Dropdown Menu for Mobile */}
      {isMobile && menuOpen && (
        <ul style={{
          position: 'absolute',
          top: '60px', // Place it right below the navbar
          left: 0,
          width: '100%',
          backgroundColor: '#222',
          color: 'white',
          listStyleType: 'none',
          padding: '10px 0',
          margin: 0,
          textAlign: 'center',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        }}>
          <li style={{ padding: '10px 0' }}>
            <Link to="/register" style={{ color: 'white', textDecoration: 'none' }}>Create Account</Link>
          </li>
          <li style={{ padding: '10px 0' }}>
            <Link to="/dashboard" style={{ color: 'white', textDecoration: 'none' }}>Dashboard</Link>
          </li>
          <li style={{ padding: '10px 0' }}>
            <Link to="/stripe-payment" style={{ color: 'white', textDecoration: 'none' }}>Buy Credits</Link>
          </li>
          <li style={{ padding: '10px 0' }}>
            <Link to="/crypto-payment" style={{ color: 'white', textDecoration: 'none' }}>Pay with Crypto</Link>
          </li>
          {username ? (
            <li style={{ padding: '10px 0' }}>
              <Link to="#" onClick={handleLogout} style={{ color: 'white' }}>Log Out</Link>
            </li>
          ) : (
            <li style={{ padding: '10px 0' }}>
              <Link to="/login" style={{ color: 'white' }}>Login</Link>
            </li>
          )}
        </ul>
      )}
    </nav>
  );
}

export default Navbar;

