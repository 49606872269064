import { jwtDecode } from "jwt-decode"; // Correct import according to the documentation

// Function to get the logged-in username from the JWT token
export const getLoggedInUsername = () => {
  const token = localStorage.getItem('token');
  if (!token) return null;

  try {
    const decodedToken = jwtDecode(token); // Correctly decode the token using jwtDecode
    const currentTime = Date.now() / 1000; // Current time in seconds
    if (decodedToken.exp > currentTime) {
      return decodedToken.username; // Retrieve the username from the decoded token
    }
    return null;
  } catch (error) {
    console.error('Error decoding token:', error);
    return null;
  }
};

// Function to check if the user is logged in
export const isUserLoggedIn = () => {
  return !!getLoggedInUsername(); // Returns true if a valid username is found, otherwise false
};

// Function to extract user ID from the JWT token
export const getUserIdFromToken = () => {
  const token = localStorage.getItem('token');
  if (!token) return null;

  try {
    const decodedToken = jwtDecode(token); // Decode the token
    return decodedToken.sub; // Replace 'sub' with the correct property for user ID in your token payload
  } catch (error) {
    console.error('Error decoding token:', error);
    return null;
  }
};
