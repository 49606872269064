import React, { useState } from 'react';
import axios from 'axios';
import Modal from './Modal';
import { isUserLoggedIn, getUserIdFromToken } from './utils/auth';
import './CreditCall.css';

function CreditCall() {
  const [symbol, setSymbol] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [analysisResult, setAnalysisResult] = useState('');
  const [modalMessage, setModalMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    if (!isUserLoggedIn()) {
      setModalMessage('You need to be logged in to make a request.');
      setIsModalOpen(true);
      setIsLoading(false);
      return;
    }

    try {
      const userId = getUserIdFromToken();
      if (!userId) {
        setModalMessage('User not authenticated.');
        setIsModalOpen(true);
        setIsLoading(false);
        return;
      }

      const response = await axios.post('https://finanon.com/api/analyze', {
        symbol: symbol.toUpperCase(),
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (response.status === 200) {
        setAnalysisResult(response.data.result);
      } else {
        setModalMessage('Failed to fetch analysis. Please try again.');
        setIsModalOpen(true);
      }
    } catch (error) {
      console.error('Error fetching analysis:', error);
      setModalMessage('An error occurred. Please try again later.');
      setIsModalOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="credit-call-container">
      <h2 className="credit-call-heading">Analyze Stock Ticker</h2>
      <form onSubmit={handleSubmit} className="credit-call-form">
        <label htmlFor="symbol" className="credit-call-label">Enter Stock Ticker:</label>
        <input
          type="text"
          id="symbol"
          value={symbol}
          onChange={(e) => setSymbol(e.target.value.toUpperCase())}
          maxLength={5}
          required
          placeholder="Enter ticker"
          className="credit-call-input"
        />
        <button type="submit" disabled={isLoading} className="credit-call-button">
          {isLoading ? 'Processing...' : 'Submit'}
        </button>
      </form>

      {analysisResult && (
        <div className="credit-call-result">
          <h3 className="credit-call-result-heading">Analysis Result:</h3>
          <div
            className="credit-call-result-content"
            dangerouslySetInnerHTML={{ __html: analysisResult }}
          />
        </div>
      )}

      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <h2 className="credit-call-modal-heading">Notice</h2>
        <p className="credit-call-modal-content">{modalMessage}</p>
      </Modal>
    </div>
  );
}

export default CreditCall;
