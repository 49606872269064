import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Dashboard.css'; // Import the new CSS

const Dashboard = () => {
  const [userData, setUserData] = useState({});
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          setError('You are not logged in.');
          return;
        }
        const response = await axios.get('https://finanon.com/api/user/profile', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUserData(response.data);
      } catch (err) {
        console.error('Error fetching user data:', err);
        setError('Failed to load user data. Please try again later.');
      }
    };
    fetchData();
  }, []);

  return (
    <div className="dashboard-container">
      <h2 className="dashboard-heading">User Dashboard</h2>
      {error && <p className="dashboard-error">{error}</p>}
      {userData && userData.username ? (
        <div className="dashboard-user-data">
          <p>Username: {userData.username}</p>
          <p>Email: {userData.email}</p>
          <p>Credits: {userData.api_call_credits}</p>
        </div>
      ) : (
        !error && <p>Loading user data...</p>
      )}
    </div>
  );
};

export default Dashboard;
