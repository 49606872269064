import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Login.css'; 
import Modal from './Modal'; 

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null); // State for success message
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Clear any existing token
      localStorage.removeItem('token');

      const response = await axios.post('https://finanon.com/api/login', {
        email,
        password,
      });

      // Store the JWT token in localStorage
      localStorage.setItem('token', response.data.access_token);

      // Trigger a custom event to notify other components
      window.dispatchEvent(new Event('authChanged'));

      // Display success message in the modal
      setSuccessMessage('Login successful! Redirecting to dashboard...');

      // Redirect to dashboard after a brief delay
      setTimeout(() => {
        setSuccessMessage(null);
        navigate('/dashboard');
      }, 2000);
    } catch (err) {
      console.error(err);
      setError('Invalid credentials'); // Set error to display in modal
    }
  };

  return (
    <div className="login-container">
      <div className="login-form">
        <h2>Login</h2>
        <form onSubmit={handleSubmit}>
          <div>
            <label>Email</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div>
            <label>Password</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit">Login</button>
        </form>
      </div>
      
      {/* Display success or error message in modal */}
      {(successMessage || error) && (
        <div className="modal-overlay">
          <div className="modal-content">
            <p>{successMessage || error}</p>
            <button className="modal-close" onClick={() => {
              setError(null);
              setSuccessMessage(null);
            }}>
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Login;
