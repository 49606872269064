import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Landing from './components/Landing'; // Import the Landing component
import Login from './components/Login';
import Register from './components/Register';
import Dashboard from './components/Dashboard';
import CryptoPayment from './components/CryptoPayment';
import StripePayment from './components/StripePayment';
import CreditCall from './components/CreditCall';
import './index.css';

function App() {
  return (
    <Router>
      <div className="content-wrapper">
        <Navbar />
        <Routes>
          <Route path="/" element={<Landing />} /> {/* Default route */}
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/crypto-payment" element={<CryptoPayment />} />
          <Route path="/stripe-payment" element={<StripePayment />} />
          <Route path="/credit-call" element={<CreditCall />} /> 
        </Routes>
      </div>
    </Router>
  );
}

export default App;
