import React from 'react';
import './Landing.css';
import StripePayment from './StripePayment'; // Import StripePayment
import CreditCall from './CreditCall'; // Import CreditCall

function Landing() {
  return (
    <div className="landing-container">
      <h1 className="landing-title">Democratizing Financial Analysis.</h1>
      <p className="landing-text">Backed by math. Powered by AI.</p>

      <h2 className="secondlanding-title">Request, retrieve, anonymize, and analyze.</h2>

      {/* New bubble-style div wrapping p2 and p3 */}
      <div className="bubble-container">
        <p className="secondlanding-text">
          FinAnon applies novel cleaning to anonymize and process 10-K and 10-Q forms of publicly traded companies.
        </p>
        <p className="thirdlanding-text">
          Purchase API credits, enter a ticker, and receive an LLM analysis of any stock ticker.
        </p>
      </div>

      {/* StripePayment section */}
      <div className="stripe-payment-section">
        <StripePayment /> {/* Render StripePayment.js below the landing content */}
      </div>

      {/* CreditCall section */}
      <div className="credit-call-section">
        <CreditCall /> {/* Render CreditCall.js below StripePayment */}
      </div>
    </div>
  );
}

export default Landing;
