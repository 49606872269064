import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';
import { isUserLoggedIn, getUserIdFromToken } from './utils/auth';
import './StripePayment.css';
import Modal from './Modal';

const stripePromise = loadStripe(process.env.REACT_APP_HOOK);

const CheckoutForm = ({ selectedAmount, setModalMessage }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = async (event) => {
    console.log('Submit button clicked');
    event.preventDefault();
    setIsProcessing(true);
    setErrorMessage(null);

    if (!stripe || !elements) {
      console.log('Stripe or elements not loaded yet');
      setErrorMessage("Stripe hasn't loaded yet. Please try again.");
      setIsProcessing(false);
      return;
    }

    console.log('Stripe and elements loaded');
    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: 'https://finanon.com/payment-success',
      },
      redirect: 'if_required',
    });

    console.log('Payment confirmation result:', result);

    if (result.error) {
      console.log('Payment confirmation error:', result.error);
      setErrorMessage(result.error.message);
      setModalMessage(result.error.message); // Set modal message for an error
    } else {
      console.log('Payment successful!');
      setModalMessage("Payment successful!"); // Set modal message for success
    }

    setIsProcessing(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
      <button type="submit" disabled={!stripe || isProcessing} style={{ marginTop: '20px' }}>
        {isProcessing ? 'Processing...' : `Pay $${selectedAmount / 100}`}
      </button>
    </form>
  );
};

const StripePayment = () => {
  const [clientSecret, setClientSecret] = useState('');
  const [selectedAmount, setSelectedAmount] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal open state
  const [modalMessage, setModalMessage] = useState(''); // Modal message state
  const [isCheckingStatus, setIsCheckingStatus] = useState(false); // Polling control
  const navigate = useNavigate();
  const userId = getUserIdFromToken();

  useEffect(() => {
    console.log('Component mounted');
    // Start polling for payment status
    if (isCheckingStatus) return;

    setIsCheckingStatus(true);
    const interval = setInterval(async () => {
      console.log('Polling for payment status...');
      try {
        const response = await fetch(`https://finanon.com/api/get-payment-status?user_id=${userId}`);
        const data = await response.json();

        console.log('Payment status message:', data.payment_status_message);

        if (data.payment_status_message) {
          console.log('Payment status message received:', data.payment_status_message);
          setModalMessage(data.payment_status_message);
          setIsModalOpen(true);

          // Stop polling since the status message is retrieved
          clearInterval(interval);
          setIsCheckingStatus(false);
        }
      } catch (error) {
        console.error('Error checking payment status:', error);
      }
    }, 3000); // Poll every 3 seconds

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, [userId, isCheckingStatus]);

  // Open the modal as soon as the modalMessage changes
  useEffect(() => {
    if (modalMessage) {
      setIsModalOpen(true);
    }
    console.log('Modal message updated:', modalMessage);
  }, [modalMessage]);

  useEffect(() => {
    console.log('Is modal open updated:', isModalOpen);
  }, [isModalOpen]);

  const handleBuyNow = (amount, credits) => {
    console.log('Buy now button clicked');
    if (!isUserLoggedIn()) {
      console.log('User not logged in');
      setModalMessage("You need to be logged in to make a purchase.");
      setIsModalOpen(true);
      setTimeout(() => {
        setIsModalOpen(false);
        navigate('/login');
      }, 2000);
      return;
    }

    handlePackageSelect(amount, credits);
  };

  const handlePackageSelect = async (amount, credits) => {
    console.log('Package select button clicked');
    if (!userId) {
      console.error("User not authenticated.");
      return;
    }

    try {
      console.log('Creating payment intent...');
      const response = await fetch('https://finanon.com/api/create-payment-intent', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          amount,
          metadata: {
            user_id: userId,
            credits: credits,
          },
        }),
      });
      const data = await response.json();
      if (data.clientSecret) {
        console.log('Client secret received:', data.clientSecret);
        setClientSecret(data.clientSecret);
        setSelectedAmount(amount);
      } else {
        console.log('Failed to load payment information');
        throw new Error('Failed to load payment information');
      }
    } catch (error) {
      console.error('Error fetching client secret:', error);
      setModalMessage('Error fetching payment information.');
      setIsModalOpen(true);
    }
  };

  const closeModal = () => {
    console.log('Modal closed');
    setIsModalOpen(false);
  };

  const options = {
    clientSecret,
  };

  return (
    <div>
      {/* Bubble container for the heading and description */}
      <div className="stripe-payment-container">
        <h2>Select a Package</h2>
        <p>
          Choose the credits package that best suits your needs and proceed with a secure payment using Stripe. Each stock ticker analysis requires 1 credit.
        </p>
      </div>

      {/* Packages without a shared white background */}
      <div className="package-options">
        <div className="package" onClick={() => handleBuyNow(1000, 5)}>
          <h3>5 Credits</h3>
          <p>$10</p>
          <button>Buy Now</button>
        </div>
        <div className="package" onClick={() => handleBuyNow(3000, 20)}>
          <h3>20 Credits</h3>
          <p>$30</p>
          <button>Buy Now</button>
        </div>
        <div className="package" onClick={() => handleBuyNow(6500, 50)}>
          <h3>50 Credits</h3>
          <p>$65</p>
          <button>Buy Now</button>
        </div>
      </div>

      {/* Modal for displaying messages */}
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <p>{modalMessage}</p>
      </Modal>
    </div>
  );
};

export default StripePayment;
